const localStorage = window.localStorage;

export function getValue(key) {
    return localStorage.getItem(key);
}

export function setValue(key, value) {
    localStorage.setItem(key, value);
}

export function removeValue(key) {
    localStorage.removeItem(key);
}

export default {
    getValue,
    setValue,
    removeValue,
}