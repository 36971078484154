import { useState } from "react";
import { postNew } from "../services/listingService.js";
import "./styles/upload.css";
import { useNavigate } from "react-router-dom";

export default function (props) {
  const [fields, setFields] = useState({
    title: "",
    thumbPath: "",
    thumbnail: Object,
    file: Object,
  });
  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    await postNew(fields);
    navigate("/");
  };

  const handleChnage = (fieldName, e) => {
    const fieldsCopy = { ...fields };
    fieldsCopy[fieldName] = e.target.value;
    setFields(fieldsCopy);
    console.log(fields);
  };

  const handleFileChange = (fieldName, e) => {
    const fieldsCopy = { ...fields };
    console.log(e.target.files);
    fieldsCopy[fieldName] = e.target.files[0];
    setFields(fieldsCopy);
    console.log(fields);
  };

  return (
    <div className="upload-form">
      <form method="POST" onSubmit={(e) => submit(e)}>
        <div>אפלאוד א נייע ווידיא</div>
        <label htmlFor="title">ווידיא נאמען</label>
        <input
          id="title"
          value={fields.title}
          type="text"
          name="title"
          placeholder="ווידיא נאמען"
          onChange={(e) => handleChnage("title", e)}
        />
        <label htmlFor="thumbPath">טאמבנעיל לאקאציע</label>
        <input
          id="thumbPath"
          value={fields.thumbPath}
          type="text"
          name="thumbPath"
          placeholder="טאמבנעיל לאקאציע"
          onChange={(e) => handleChnage("thumbPath", e)}
        />
        <label htmlFor="thumbnail">קלויב אויס א ווידיא פייל</label>
        <input
          id="thumbnail"
          lang="yi"
          type="file"
          name="video"
          onChange={(e) => handleFileChange("file", e)}
        />
        <label htmlFor="video">קלויב אויס א טאמבנעיל פייל</label>
        <input
          id="video"
          lang="yi"
          type="file"
          name="video"
          onChange={(e) => handleFileChange("thumbnail", e)}
        />
        <button type="submit">אפלאוד</button>
      </form>
    </div>
  );
}
