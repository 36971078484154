import { useLocation, useParams } from "react-router-dom";
import "./styles/video.css";
import { getVideo, getVideoRequest } from "../services/listingService";
import ReactPlayer from "react-player/lazy";
import { useEffect, useState } from "react";

export default function Video(props) {
  const passedState = useLocation().state;
  let suggestionId = "";
  if (passedState) suggestionId = passedState.suggestionId;
  const { id } = useParams();

  const [videoRequest, setVideoRequest] = useState({
    _id: "",
    SuggestionId: "",
  });

  const getVideoSrc = () => {
    if (videoRequest && videoRequest._id) {
      return `https://kzmgeb-ip-104-28-196-211.tunnelmole.net/video/req/${videoRequest._id}`;
    } else {
      return "";
    }
  };

  const fetchVideoRequest = async () => {
    const videoRequest = await getVideoRequest(id, suggestionId);
    setVideoRequest(videoRequest);
  };

  const [video, setVideo] = useState({
    title: "",
  });

  const fetchvideo = async () => {
    const video = await getVideo(id);
    setVideo(video);
  };

  useEffect(() => {
    fetchvideo();
    fetchVideoRequest();
  }, []);

  return (
    <div className="video-container">
      {/* <ReactPlayer id="video" height="100%" width="100%" playing url={src} controls /> */}
      <video
        id="video"
        controls
        autoPlay
        src={getVideoSrc()}
        // src="https://dl.dropboxusercontent.com/scl/fi/tp8qo1y7ut73pvzesgl6c/Zanvil-weinberger-Vesechezena-Dirshu-Syum-USA.mp4?rlkey=byd6l4wgxlirzrnf770razhv0&dl=0"
      ></video>
      {/* <div className="video-controls">
        <button
          onClick={() => {
            document.getElementById("video").pip = true;
          }}
        >
          Fullscreen
        </button>
      </div> */}
      <div className="creator-icon">
        <img
          src="https://www.mayerfried.dev/_next/image?url=%2Fimages%2Fprofile.jpg&w=384&q=75"
          alt=""
        />
      </div>
      <div className="video-caption">
        <span>{video.title}</span>
      </div>
    </div>
  );
}
