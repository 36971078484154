import { useState } from "react";
import userService from "../services/userService";
import { useNavigate } from "react-router-dom";
import "./styles/login.css";

export default function Login(props) {
  const [fields, setFields] = useState({
    Email: "",
    Password: "",
  });

  const onChange = (field, e) => {
    const updatedFields = { ...fields };
    updatedFields[field] = e.target.value;
    setFields(updatedFields);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { Email, Password } = fields;
    if (!Email || !Password) return alert("Please enter email and password!");
    await userService.authUser(Email, Password);
    window.location = "/";
  };

  return (
    <div className="form-container">
      <form method="post" onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="email">אימעיל</label>
        <input
          id="email"
          type="text"
          name="email"
          placeholder="אימעיל"
          required
          value={fields.Email}
          onChange={(e) => onChange("Email", e)}
        />
        <label htmlFor="password">פעסווארט</label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="אימעיל"
          required
          value={fields.Password}
          onChange={(e) => onChange("Password", e)}
        />
        <button type="submit">לאג אריין</button>
      </form>
    </div>
  );
}
