import jwtDecode from "jwt-decode";
import httpService from "../utils/httpService";
import localStorage from "../utils/localStorage";

const baseUrl = "https://jfawom-ip-104-28-196-211.tunnelmole.net/users";
const JWT_KEY = "x-auth-token";

httpService.setJwtGetter(getJwt);

export async function postNewUser(userData) {
    const {
        data: jwt
    } = await httpService.post(baseUrl, userData);
    localStorage.setValue(JWT_KEY, jwt);
    return jwt;
}

export async function authUser(Email, Password) {
    const {
        data: jwt
    } = await httpService.post(baseUrl + "/auth", {
        Email,
        Password
    });
    localStorage.setValue(JWT_KEY, jwt);
    return jwt;
}

export function signOut() {
    localStorage.removeValue(JWT_KEY);
}

export function getJwt() {
    return localStorage.getValue(JWT_KEY);
}

export function getUser() {
    const jwt = localStorage.getValue(JWT_KEY);
    if (!jwt) return null
    return jwtDecode(jwt);
}

export default {
    postNewUser,
    authUser,
    signOut,
    getUser
}