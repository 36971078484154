import axios from "axios";

let getJwt;

axios.interceptors.request.use((config) => {
    config.headers["x-auth-token"] = getJwt();
    return config;
})

export function setJwtGetter(func) {
    getJwt = func;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwtGetter
}