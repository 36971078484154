import "./styles/listing.css";
import { Link } from "react-router-dom";

export default function Listing(props) {
  const getTitle = () => {
    const maxLength = 80;
    const caption = props.caption;
    if (caption.length > maxLength) {
      return caption.substring(0, maxLength - 3) + "...";
    } else {
      return caption;
    }
  };

  return (
    <div className="container">
      <Link
        to={`/video/${props.videoId}`}
        state={{ suggestionId: props.suggestionId }}
      >
        <div className="tumbnail">
          <img
            src={`https://kzmgeb-ip-104-28-196-211.tunnelmole.net/thumbnail/${props.suggestionId}`}
            alt={props.caption}
          />
        </div>
        <div className="caption">
          <span>{getTitle()}</span>
        </div>
      </Link>
    </div>
  );
}
