import { getUser } from "../services/userService";
import "./styles/navbar.css";

export default function NavBar() {
  const user = getUser();
  return (
    <div className="nav-bar">
      <a href="/">
        <div className="logo">
          <span>Zayzing</span>
          <span className="com">.com</span>
        </div>
      </a>
      <ul>
        <li>
          <a href="/">היים</a>
        </li>
        <li>
          <a href="/library">לייברערי</a>
        </li>
        <li>
          <a href="/search">זוך</a>
        </li>
        {user && (
          <li>
            <a href="/profile">{user.FirstName + " " + user.LastName}</a>
          </li>
        )}
        {!user && (
          <li>
            <a href="/profile">פראפייל</a>
          </li>
        )}
        <li>
          <a href="/addvideotag">נייע טעג</a>
        </li>
        <li>
          <a href="/upload">אפלאוד</a>
        </li>
        <li>
          <a href="/help">הילף</a>
        </li>
        {!user && (
          <li>
            <a href="/auth">לאג אריין</a>
          </li>
        )}
        {user && (
          <li>
            <a href="/logout">לאג ארויס</a>
          </li>
        )}
      </ul>
      <span className="copyright">Copyright 2023 ZayZing.com &copy;</span>
    </div>
  );
}
