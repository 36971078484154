import { useState } from "react";
import "./styles/new-user.css";
import { postNewUser } from "../services/userService";
import { useNavigate } from "react-router-dom";

export default function NewUser(props) {
  const [fields, setFields] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    PasswordConfirm: "",
  });

  const navigate = useNavigate();

  const onChange = (field, e) => {
    const updatedFields = { ...fields };
    updatedFields[field] = e.target.value;
    setFields(updatedFields);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { FirstName, LastName, Email, Password, PasswordConfirm } = fields;
    if (!FirstName) return prompt("First Name required");
    if (!FirstName) return prompt("Last Name required");
    if (!FirstName) return prompt("Email required");
    if (!FirstName) return prompt("Password required");
    if (!FirstName) return prompt("Please confirm the password");
    if (Password != PasswordConfirm) return prompt("Passwords don't match");
    console.log("ok");
    const jwt = await postNewUser({
      FirstName,
      LastName,
      Email,
      Password,
    });
    prompt(jwt);
    navigate("/");
  };

  return (
    <div className="form-container">
      <form method="POST" onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="first-name">ערשטע נאמען:</label>
        <input
          id="first-name"
          type="text"
          name="first-name"
          placeholder="ערשטע נאמען"
          required
          value={fields.FirstName}
          onChange={(e) => onChange("FirstName", e)}
        />
        <label htmlFor="last-name">לעצטע נאמען:</label>
        <input
          id="last-name"
          type="text"
          name="last-name"
          placeholder="לעצטע נאמען"
          required
          value={fields.LastName}
          onChange={(e) => onChange("LastName", e)}
        />
        <label htmlFor="email">אימעיל:</label>
        <input
          id="email"
          type="text"
          name="email"
          placeholder="אימעיל"
          required
          value={fields.Email}
          onChange={(e) => onChange("Email", e)}
        />
        <label htmlFor="password">פעסווארט:</label>
        <input
          id="password"
          type="password"
          name="password"
          placeholder="פעסווארט"
          required
          value={fields.Password}
          onChange={(e) => onChange("Password", e)}
        />
        <label htmlFor="confirm-password">באשטעטיג פעסווארט:</label>
        <input
            id="confirm-password"
            type="password"
            name="confirm-password"
            placeholder="באשטעטיג פעסווארט"
            required
            value={fields.PasswordConfirm}
            onChange={(e) => onChange("PasswordConfirm", e)}
        />
        <button type="submit">גיי</button>
      </form>
    </div>
  );
}
