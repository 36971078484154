import { useEffect, useState } from "react";
import "./styles/header.css";
import { getTags } from "../services/videoTagService";

export default function Header() {
  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    const tags = await getTags();
    setTags(tags);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <div className="cat-container">
      {tags.map((tag) => (
        <span id={tag._id} className="cat">
          {tag.name}
        </span>
      ))}
    </div>
  );
}
