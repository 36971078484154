import logo from "./logo.svg";
import "./App.css";
import NavBar from "./compnents/navbar";
import Listing from "./compnents/listing";
import ListingContainer from "./compnents/ListingContainer";
import Header from "./compnents/header";
import {
  Route,
  Router,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Video from "./compnents/video";
import React from "react";
import Upload from "./compnents/upload";
import NewVideoTag from "./compnents/NewVideoTag";
import NewUser from "./compnents/newUser";
import Login from "./compnents/login";
import Logout from "./compnents/logout";

function App(props) {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ListingContainer />,
    },
    {
      path: "/video/:id",
      element: <Video {...props} />,
    },
    {
      path: "/upload",
      element: <Upload {...props} />,
    },
    {
      path: "/addvideotag",
      element: <NewVideoTag {...props} />,
    },
    {
      path: "/auth",
      element: <Login {...props} />,
    },
    {
      path: "/logout",
      element: <Logout {...props} />,
    },
    {
      path: "/register",
      element: <NewUser {...props} />,
    },
  ]);
  document.title = "ZayZing.com";
  return (
    <div className="App">
      <NavBar></NavBar>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
