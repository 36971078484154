import httpService from "../utils/httpService.js";
const BASE_URL = "https://jfawom-ip-104-28-196-211.tunnelmole.net";

export async function getSuggestions() {
    const {
        data: videos
    } = await httpService.get(`${BASE_URL}/videos/suggest`);
    return videos;
}

export async function getVideo(id) {
    const {
        data: video
    } = await httpService.get(`${BASE_URL}/videos/${id}`);
    return video;
}

export async function getVideoRequest(videoId, suggestionId) {
    const {
        data: request
    } = await httpService.get(`${BASE_URL}/request/video/${videoId}${suggestionId ? `?suggestionId=${suggestionId}` : ""}`)
    return request;
}

export async function postNew(formData) {
    console.log(formData);
    const {
        data: result
    } = await httpService.post(`${BASE_URL}/videos/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return result;
}