import { useState } from "react";
import { postNewTag } from "../services/videoTagService";
import { useNavigate } from "react-router-dom";
import "./styles/new-video-tag.css";

export default function NewVideoTag(props) {
  const [fields, setFields] = useState({ name: "" });
  const navigate = useNavigate();

  const submitForm = async (e) => {
    e.preventDefault();
    await postNewTag(fields.name);
    navigate("/");
  };

  const onFieldChanged = (field, e) => {
    const updatedFields = { ...fields };
    updatedFields[field] = e.target.value;
    setFields(updatedFields);
  };

  return (
    <div className="form-container">
      <form method="POST" onSubmit={(e) => submitForm(e)}>
        <label htmlFor="name">טעג נאמען:</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Tag Name"
          required
          onChange={(e) => onFieldChanged("name", e)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
