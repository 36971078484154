import Header from "./header";
import Listing from "./listing";
import "./styles/listing-container.css";
import { getSuggestions } from "../services/listingService.js";
import { useEffect, useState } from "react";

export default function ListingContainer() {
  const [listings, setListings] = useState([]);

  const populate = async () => {
    const suggestions = await getSuggestions();
    setListings(suggestions);
  };

  useEffect(() => {
    populate();
  }, []);

  return (
    <div className="listing-container">
      <Header/>
      {listings.map((listing) => (
        <Listing
          suggestionId={listing.suggestionId}
          videoId={listing.videoId}
          key={listing.suggestionId}
          caption={listing.videoTitle}
        />
      ))}
    </div>
  );
}
