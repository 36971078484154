import httpService from "../utils/httpService";
const baseUrl = "https://jfawom-ip-104-28-196-211.tunnelmole.net/tags/";

export async function getTags() {
    const {
        data: tags
    } = await httpService.get(baseUrl);
    return tags;
}

export async function postNewTag(name) {
    const {
        data: result
    } = await httpService.post(baseUrl, {
        name
    });
    return result;
}